export default [
  {
    title: 'B-hack Barbórka 4.0',
    date: new Date(2020, 11, 4),
    url: 'https://www.b-hack.pl/',
    location: 'Online',
    akai: false,
    tags: [],
  },
  {
    title: 'HackYeah - the biggest stationary hackathon in Europe',
    date: new Date(2020, 10, 27),
    url: 'https://hackyeah.pl/',
    location: 'Online',
    akai: true,
    tags: [],
  },
  {
    title: '4. Bydgoski Hackathon 2020 online',
    date: new Date(2020, 10, 20),
    url: 'https://bki.org.pl/hackathon/',
    location: 'Online',
    tags: [],
  },
  {
    title: 'Hackathon dla Miast',
    date: new Date(2020, 10, 20),
    url: 'https://pfrdlamiast.pl/hackathon',
    location: 'Online',
    tags: [],
  },
  {
    title: 'Hack into Mars - FPGA Hackathon 2020',
    date: new Date(2020, 10, 20),
    url: 'https://hackathon.fpgahackathon.com/register/team',
    location: 'Online',
    tags: [],
  },
  {
    title: 'Innovation@Amazon Online Hackathon',
    date: new Date(2020, 10, 6),
    url: 'https://innovation-amazon.com/',
    location: 'Online',
    tags: [],
  },
  {
    title: 'Iraq Hackathon',
    date: new Date(2020, 10, 6),
    url: 'iraqhackathon.com',
    location: 'Online',
    tags: [],
  },
  {
    title: 'Spacehack',
    date: new Date(2020, 10, 23),
    url: 'http://spacehack.pl/',
    location: 'Online',
    akai: true,
    tags: [],
  },
  {
    title: 'Hacktoberfest',
    date: new Date(2020, 9, 1),
    url: 'https://hacktoberfest.digitalocean.com/',
    location: 'Online',
    tags: [],
  },
  {
    title: 'II Hackathon Kognitywistyczny Cog_HACK',
    date: new Date(2020, 2, 28),
    url: 'https://www.facebook.com/events/616646389152894/',
    location: 'Poznań',
    tags: [],
  },
  {
    title: 'Wrocław Agile Development Hackathon',
    date: new Date(2020, 2, 14),
    url: 'https://www.facebook.com/events/536950876911003/',
    location: 'Wrocław',
    tags: [],
  },
  {
    title: 'Copernicus Hackathon',
    date: new Date(2020, 2, 7),
    url: 'https://www.facebook.com/events/2469536026596600/',
    location: 'Łódź',
    tags: [],
  },
  {
    title: 'PGG JAM - ALL PLAY: ACCESSIBILITY vol. 4',
    date: new Date(2019, 10, 29),
    url: 'http://gildiagraczy.pl/pgg-jam-all-play/',
    location: 'Poznań',
    tags: ['gamedev'],
  },
  {
    title: 'Mage Jam - Edycja 2',
    date: new Date(2019, 9, 25),
    url: 'https://www.facebook.com/events/2177556659032774/',
    location: 'Poznań',
    tags: ['gamedev'],
  },
  {
    title: 'HackYeah 2019',
    date: new Date(2019, 8, 14),
    url: 'https://hackyeah.pl/',
    location: 'Warszawa',
    tags: [],
  },
  {
    title: 'Hackathon BrainCode 2019',
    date: new Date(2019, 3, 12),
    url:
      'https://www.eventbrite.com/e/hackathon-braincode-2019-tickets-58268030247',
    location: 'Poznań',
    tags: [],
  },
  {
    title: 'Addicton 2019',
    date: new Date(2019, 2, 30),
    url: 'http://addicton.pl/',
    location: 'Poznań',
    tags: [],
  },
  {
    title: 'JavaScript Hackathon & ServiceNow Meetup',
    date: new Date(2019, 1, 27),
    url: 'https://spoc.pl/servicenow-meetup-hackathon-february-27th-poznan/',
    location: 'Poznań',
    tags: [],
  },
  {
    title: 'Code4Life Hackathon',
    date: new Date(2018, 9, 6),
    url: 'https://code4life.pl/pages/hackathons',
    location: 'Warszawa',
    tags: [],
  },
  {
    title: 'Geek Jam',
    date: new Date(2018, 9, 10),
    url: 'https://gic.gd/geekjam/',
    location: 'Poznań',
    tags: ['gamedev'],
  },
  {
    title: 'HackYeah',
    date: new Date(2018, 10, 24),
    url: 'https://hackyeah.pl/',
    location: 'Warszawa',
    description: 'The biggest stationary hackathon in the world!',
    akai: true,
    tags: [],
  },
  {
    title: 'Nowa strona danych',
    date: new Date(2018, 9, 27),
    url: 'https://hackathon.gov.pl',
    location: 'Warszawa',
    tags: [],
  },
  {
    title: '#MAP_IT! Mapping Hackathon',
    date: new Date(2018, 10, 17),
    url: 'http://www.map-it.com.pl/',
    location: 'Kraków',
    tags: [],
  },
  {
    title: 'CodePlay by Asseco 2018',
    date: new Date(2018, 9, 27),
    url: 'https://codeplay.asseco.com/',
    location: 'Kraków',
    tags: [],
  },
  {
    title: 'Mercari Euro Hack 2018',
    date: new Date(2018, 9, 20),
    url: 'https://challengerocket.com/mercari',
    location: 'Warszawa',
    tags: [],
  },
  {
    title: "Let's Code",
    date: new Date(2018, 10, 17),
    duration: '24h',
    url: 'http://letscode.sii.pl',
    location: 'Poznań',
    akai: true,
    tags: [],
  },
  {
    title: 'Paysafe Global Hackathon 2018',
    date: new Date(2018, 10, 27),
    url: 'https://app.codility.com/l/paysafe_hackathon2018/',
    location: 'online',
    tags: [],
  },
  {
    title: 'First Hackathon in VWP - Volkswagen',
    date: new Date(2018, 9, 30),
    url: 'http://volkswagen-poznan.pl/pl/hackathon',
    location: 'Poznań',
    akai: true,
    tags: [],
  },
  {
    title: 'Vue Storefront Hackathon #3',
    date: new Date(2018, 9, 26),
    url:
      'https://medium.com/@office_24377/vue-storefront-hackathon-3-13f652b60cf5',
    location: 'Poznań',
    tags: [],
  },
  {
    title: 'PGG JAM: ALL PLAY Vol. 3',
    date: new Date(2018, 10, 30),
    url: 'http://gildiagraczy.pl/pgg-jam-all-play/',
    location: 'Poznań',
    tags: ['gamedev'],
  },
  {
    title: 'Sensei Game Jam 2018',
    date: new Date(2018, 11, 7),
    url: 'http://www.senseigamejam.pl/',
    location: 'Wrocław',
    description: '#senseigj',
    tags: ['gamedev'],
  },
  {
    title: 'Poznań Game Jam',
    date: new Date(2019, 0, 25),
    url: 'https://globalgamejam.org/2019/jam-sites/poznan-game-jam',
    location: 'Poznań',
    tags: ['gamedev'],
  },
  {
    title: 'Vue Storefront Hackathon #4',
    date: new Date(2019, 0, 18),
    url:
      'https://www.eventbrite.com/e/vue-storefront-hackathon-4-tickets-54166854520',
    location: 'Wrocław',
    tags: [],
  },
  {
    title: 'Hackathon Brainhack Warsaw 2019',
    date: new Date(2019, 2, 1),
    url: 'http://psych.uw.edu.pl/2019/01/09/hackathon-brainhack-warsaw-2019/',
    location: 'Warszawa',
    tags: [],
  },
  {
    title: 'Hackathon Banku Pekao S.A.',
    date: new Date(2019, 1, 22),
    url: 'http://psych.uw.edu.pl/2019/01/09/hackathon-brainhack-warsaw-2019/',
    location: 'wiele miast',
    description: 'Finał w Warszawie',
    tags: [],
  },
];
